import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import documentDriven_cMiI6j6ghD from "/opt/build/repo/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import preview_client_DXs5LYnWiu from "/opt/build/repo/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import pinceau_nuxt_plugin_client_bfcCy9a5iq from "/opt/build/repo/.nuxt/pinceau-nuxt-plugin.client.mjs";
import plugin_client_i8AMfKeYnY from "/opt/build/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_client_LcKgStRyi6 from "/opt/build/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  documentDriven_cMiI6j6ghD,
  preview_client_DXs5LYnWiu,
  pinceau_nuxt_plugin_client_bfcCy9a5iq,
  plugin_client_i8AMfKeYnY,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes
]