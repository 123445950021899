export default defineAppConfig({
  alpine: {
    title: '',
    description: 'Welcome to Ahmed Bassell\'s blog, where you\'ll find insights and perspectives on the latest trends and developments in technology, business, and innovation. With a focus on emerging technologies and cutting-edge ideas, Ahmed shares his thoughts and experiences on a wide range of topics, from artificial intelligence and blockchain to entrepreneurship and digital transformation. Join the conversation and stay up-to-date with the latest insights and trends in the world of technology and business.',
    head: {
      htmlAttrs: {
        lang: 'en'
      }
    },
    image: {
      src: 'https://bassell.me/preview2.jpeg',
      alt: 'Ahmed Bassell - Blog',
      width: 400,
      height: 300
    },
    header: {
      position: 'right', // possible value are : | 'left' | 'center' | 'right'
      logo: false,
    },
    footer: {
      credits: {
        enabled: false, // possible value are : true | false
        // repository: 'https://www.github.com/nuxt-themes/alpine' // our github repository
      },
      navigation: true, // possible value are : true | false
      alignment: 'center', // possible value are : 'none' | 'left' | 'center' | 'right'
      message: 'Follow me on' // string that will be displayed in the footer (leave empty or delete to disable)
    },
    socials: {
      instagram: {
        icon: 'uil:instagram',
        label: 'Instagram',
        href: 'https://www.instagram.com/ahmedbassell/'
      },
      linkedin: {
        icon: 'uil:linkedin',
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/in/bassell/'
      }
    },
    form: {
      successMessage: 'Message sent. Thank you!'
    }
  }
})
