import { default as document_45drivenmX3P0ZuwlnMeta } from "/opt/build/repo/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue?macro=true";
import { default as _91slug_93djqPQUcN8OMeta } from "/opt/build/repo/pages/tag/[slug].vue?macro=true";
export default [
  {
    name: document_45drivenmX3P0ZuwlnMeta?.name ?? "slug",
    path: document_45drivenmX3P0ZuwlnMeta?.path ?? "/:slug(.*)*",
    meta: document_45drivenmX3P0ZuwlnMeta || {},
    alias: document_45drivenmX3P0ZuwlnMeta?.alias || [],
    redirect: document_45drivenmX3P0ZuwlnMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93djqPQUcN8OMeta?.name ?? "tag-slug",
    path: _91slug_93djqPQUcN8OMeta?.path ?? "/tag/:slug()",
    meta: _91slug_93djqPQUcN8OMeta || {},
    alias: _91slug_93djqPQUcN8OMeta?.alias || [],
    redirect: _91slug_93djqPQUcN8OMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/tag/[slug].vue").then(m => m.default || m)
  }
]